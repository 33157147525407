var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('YoTable', {
    attrs: {
      "ref-specify": "FormType2",
      "api-endpoint": _vm.apiEndpoint,
      "title-tbl": this.$t('Dashboards') + ' Form Type 2 (T2)',
      "sub-title-tbl": this.$t('Land Vehicles'),
      "fields": _vm.fields,
      "field-history": _vm.fieldHistory,
      "new-data-template": _vm.newDataTemplate,
      "resolve-fetch-response": _vm.resolveFetchResponse,
      "resolve-fetch-url": _vm.resolveFetchUrl,
      "disable-edit": _vm.disableEdit,
      "multiple-header": _vm.multipleHeader,
      "force-disable-crud": true,
      "enable-create-form": "monitoring-jalur-darat-form-type-2-wizard",
      "placeholder-search": "LHCode"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }